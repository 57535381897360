import Vue from 'vue';
import axios from 'axios';





// configuration data
//const BASE_URL = 'http://172.16.255.20:3000/';
//var baseurl = window.location.origin;
//console.log(window.location.hostname+":3000/")


const axiosInstance = axios.create({
  // baseURL: BASE_URL,
  //baseURL: "http://127.0.0.1:3001/",
    baseURL:location.protocol+'//api.subgatenetwork.com/',
  },
);
Vue.prototype.$axios = axiosInstance

export { axiosInstance }